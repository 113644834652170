/* Footer styles */
.footer {
  background-color: #a4dbe8;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 80px;
}
.share-links-footer {
    display: flex;
    gap: 20px;
    align-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
}
.share-link-footer {
    text-decoration: none;
    color: #007BFF;
    display: flex;
    align-items: center;
}
.share-link-footer:hover {
  color: black; /* Change text color on hover */
}
.footer-icon {
    width: 40px;
    height: 40px;
}
.footer-icon-insta {
    color: red;
    width: 40px;
    height: 40px;
}
.footer-icon-insta:hover {
  color: black; /* Change text color on hover */
}

.footer-logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.footer-links ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.mainFooterLink {
  position: relative; /* Make the li container relative for absolute positioning */
}

.mainFooterLinkText {
  position: relative;
  color: black;
  text-decoration: none;
  padding-bottom: 5px; /* Add space between text and underline */
  font-size: 20px;
}

.mainFooterLinkText::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transition: width 0.5s ease-in-out;

}

.mainFooterLinkText:hover::before {
  width: 100%; /* Expand the underline to the width of the text */
}



.mainFooterLinkText:hover {
  color: white;
  transition: color .5s ease-in-out; /* Add transition for width */
}

.footer-copyright {
  margin-top: 10px;
}
.footer-logo {
  display: flex;
  align-items: center;
  float: left;
}

.footer-logo img {
  width: auto; /* Adjust the width as needed */
  height: 60px;
  margin-right: 10px; /* Add some spacing between the logo and text */
}
.footer-social{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 80px;
}
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-logo {
    margin-bottom: 10px;
  }

  .footer-links ul {
    flex-direction: column;
    gap: 10px;
    padding: 0;
    margin: 0;
  }
  .footer-social {
    flex-direction: column-reverse;
    padding: 20px 0px;
    padding-bottom: 0;
    gap: 20px;
  }
  .footer-copyright {
    font-size: 18px;
  }
  .footer {
    padding-top: 20px;
    padding-bottom: 0px;
  }
}