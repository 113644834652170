/* Home.css */

.cta-section {
  background-color: #f2f2f2;
  padding: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  animation: fadeInUp 1s ease-in-out; /* Fade in and slide up animation */
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the modal is on top of other content */
}

/* Modal */
.modal {
  background-color: #666;
  padding: 20px;
  border-radius: 5px;
}

/* Modal Image (for controlling the image size) */
.modal-image {
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

h1 {
  font-size: 50px;
  color: #333;
  margin-bottom: 20px;
}
.cta-section h1 {
    font-size: 50px;
    margin: 0;
    margin-top: 10px;
}
.cta-section p {
    margin-bottom: 0px;
    font-size: 15px;
    margin-top: 0;
}
.roboTitle{
    color: blue;
}
.testimonials-homePage {
    text-align: left;
}
.testimonials-homePage p {
    font-weight: 550;
    margin-bottom: 0;
}
.stars-testimonial{
    color: gold;
    margin: 0;
    font-size: 20px;
}
.subscriber-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
}
.subscriber-container p {
    font-size: 12px;
    font-weight: 500;
}

.subscriber-images {
  display: flex;
  margin-right: 15px; /* Adjust the spacing between images and text */
}

.subscriber-pic-cta {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: -10px;
  border: 2px solid white; /* Add your desired border color */
}


.cta-section h2 {
  font-size: 40px;
  color: #333;
  margin-top: 10px;
  margin-bottom: 10px;
}
.cta-section h3 {
    font-size: 30px;

}
.cta-section h4 {
    font-size: 20px;
}
p {
  font-size: 20px;
  color: #666;
  margin-bottom: 20px;
}

.subscription-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 500px;
  border-radius: 10px;
  background-color: #a4dbe8;
  gap: 10px;
  margin-top: 25px;
  width: 100%;
  animation: fadeInLeft 1s ease-in-out;
  padding: 5px;
  position: relative;
  box-shadow:rgb(172, 86, 255) 2px 2px 0px, rgba(0, 0, 0, 0.25) 4px 4px 0px;
}



@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

input[type="email"] {
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 20px;
  transition: border-color 0.3s ease; /* Smooth border color transition */
}

button {
  color: white;
  background-color: #e3a090;
  border: none;
  border-radius: 4px;
  padding: 20px 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, background-image 0.3s ease;
  animation: bounceIn 1s ease-in-out; /* Bounce animation */
  background-image: radial-gradient(circle, #d97d67, #dc8671, #de8f7b, #e19786, #e3a090);
  white-space: nowrap;
  font-weight: 600;
}
button:hover {
    background: rgb(164,219,232);
    background: radial-gradient(circle, rgba(164,219,232,1) 0%, rgba(0,212,255,1) 22%, rgba(61,61,227,1) 100%);
    color: black;
    font-weight: 700;
    text-decoration: underline;
    transition: all .5s ease-in-out; /* Add transition for width */
}




@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}


/* Home.css */

/* ... (previous styles) ... */

/* Sample Content */
/* Home.css */

/* ... (previous styles) ... */

/* Sample Content */

.sample-content {
  position: relative;
  padding: 60px;
  color: white;
  overflow: hidden;
  background-color: #a4dbe8;
  padding-top: 100px;

  /* Create a pseudo-element for the top half background color */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 50%; /* Set to 50% to create the top half */
    background-color: #f2f2f2; /* Change to your desired top color */
  }

  /* Create a pseudo-element for the background image */
  &::after {
    content: "";
    background-image: url('/public/images/SocialMedia/WhyRoboBackground.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 2000px 500px;
    position: absolute;
    top: -1000px;
    right: -1000px;
    bottom: -1000px;
    left: -1000px;

    /* Apply the rotation transformation to the pseudo-element */
    transform: rotate(-3deg);
  }
}



@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.sample-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sample-text {
  flex: 1;
  padding: 0 25px; /* Add spacing between text and image */
  text-align: left;
  z-index: 5;
}
.sample-text h2 {
    font-size: 30px;
    margin: 0;
    color: #a4dbe8;
}
.sample-text h1 {
    font-size: 50px;
    margin: 10px 0;
    color: #a4dbe8;
}
.sample-text p {
  font-size: 30px;
  color: white;
  margin-bottom: 10px;
}

.sample-image {
  flex: 1;
  min-width: 40%;
  z-index: 5;
}

.sample-image img {
  max-width: 100%; /* Ensure the image doesn't exceed its container's width */
  max-height: 500px; /* Adjust the max height as needed */
  height: auto; /* Maintain the image's aspect ratio */
  width: auto;
}

/* Home.css */

/* ... (previous styles) ... */
.news-feature {
    background-color: #a4dbe8;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
}
.news-feature-text{
    padding: 20px;
}
.news-feature-video {
    min-width: 50%;
    max-width: 100%;
}
.news-feature-video video {
    max-width: 90%;
    border-radius: 20px;
}
.news-feature-text h1 {

}
/* Newsletter Benefits */
.newsletter-benefits {
  padding: 40px 20px;
  background-color: #a4dbe8;
  animation: bounceInUp 1s ease-in-out; /* Bounce and slide up animation */
}

@keyframes bounceInUp {
  0%, 20%, 40%, 60%, 80%, 100% {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.newsletter-benefits h2 {
  font-size: 50px;
  color: #333;
  margin-bottom: 70px;
}

.benefits-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 25px;
}

.benefit-item {
  flex-basis: calc(33.33% - 20px); /* Adjust the width of benefit items as needed */
  margin-bottom: 20px;
  text-align: center;
}

.benefit-item img {
  max-width: 100px; /* Adjust the max width as needed */
  height: auto;
}

.benefit-item p {
  font-size: 25px;
  color: #666;
  margin-top: 10px;
}
.home-archives-list {
    display: flex;
    padding: 50px;
}
.home-archives-list .newsletter-preview:hover {
    transform: translateY(-10px);
}
.home-archives-list .newsletter-preview {
    margin: 10px;
    transition: transform 0.3s ease;
    flex-direction: column;
    flex-basis: calc(33.33% - 20px);
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.home-archives-list .newsletter-preview .container {
    height: 500px;
    overflow: hidden;
}
.home-archives-list .newsletter-preview .container .transcript{
    margin-top: 0;
}
.home-archives-list .newsletter-preview .newsletter-preview-view-full-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.home-archives-list .newsletter-preview .container .reportDiv h2 {
    font-size: 21px;
}
.home-archives-list .newsletter-preview .container .reportDiv h1 {
    font-size: 24px;
}
.home-archives-list .newsletter-preview .container .reportDiv li,
.home-archives-list .newsletter-preview .container .reportDiv p {
    font-size: 17px;
}
.home-archives-list .newsletter-preview .container .reportDiv {
    height: 450px;
    overflow: hidden;
}
.home-archives-list .newsletter-preview .container .introDiv,
.home-archives-list .newsletter-preview .container .reportDivTopStocks,
.home-archives-list .newsletter-preview .newsletter-preview-read-more-button,
.home-archives-list .newsletter-preview .container img {
    display: none !important;
}
.previous-emails-section{
    padding: 40px 20px;
}
.previous-emails-section p {
    font-size: 25px;
}
/* Home.css */

/* ... (previous styles) ... */

.blog-section {
  background-color: #f2f2f2;
  padding: 40px 20px;
  text-align: center;
  animation: fadeInUp 1s ease-in-out; /* Fade in and slide up animation */
  display: flex;
  flex-direction: column;
}
.buyMeACoffee {
    max-width: 175px;
    padding-top: 25px;
}
.buyMeACoffee:hover {
    transform: scale(1.05);
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.blog-section h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.blog-section p {
  font-size: 25px;
  color: #666;
  margin-bottom: 20px;
}
.latest-blogs {
    display: flex;
    padding: 50px;
}
.latest-blogs .article-preview {
    margin: 10px;
    transition: transform 0.3s ease; /* Add a smooth transition effect */
    flex-direction: column;
    flex-basis: calc(33.33% - 20px);
}
.latest-blogs .article-preview:hover {
    transform: translateY(-10px);
}
.latest-blogs .article-preview .preview-link {
    flex-direction: column;
    align-items: center;
}
.latest-blogs .article-preview .preview-link .preview-image {
    max-height: 250px;
    max-width: 250px;
}
.latest-blogs .article-preview .preview-link .preview-title {
    font-size: 20px;
    padding: 0;
}
.latest-blogs .article-preview .preview-link .publication-date {
    font-size: 15px;
    margin: 0;
}
.latest-blogs .article-preview .preview-excerpt p {
    font-size: 15px;
}
.home-3d-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  padding: 50px;
}
.main-section-home{
    display: flex;
    background-color: #f2f2f2;
    justify-content: space-evenly;
}
.error-message-container {
  transform: translateX(-20%);
  margin-top: 10px;
  background-color: white;
  color: black;
  border-radius: 5px;
  z-index: 1;
  position: relative;
}
.error-message {
    font-size: 15px;
    margin: 10px;
}
.cta-section h5 {
    font-size: 30px;
    margin: 0;
    margin-top: 30px;
    font-style: italic;
}
.blog-section h3 {
    font-size: 40px;
    font-style: normal;
}
.other-blogs-home {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 50px;
}
.other-blogs-home a {
    padding: 20px;
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    text-decoration: none;
    color: blue;
}
.other-blogs-home a:hover {
    transform: scale(1.01);

}
.other-blogs-home .article-preview {
    margin: 10px;
    display: block;
    box-shadow: none;
    padding: 0;
    background-color: #f2f2f2;
}
.other-blogs-home .article-preview a {
    padding: 0;
    font-size: 0;
    font-style: italic;
    font-weight: 600;
    text-decoration: none;
    text-decoration: none;
    color: blue;
}
.other-blogs-home .article-preview .preview-title {
    padding: 0px;
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    text-decoration: none;
    color: blue;
}
.other-blogs-home .article-preview .preview-image {
    display: none;
}
.other-blogs-home .article-preview .publication-date {
    display: none;
}
.other-blogs-home .article-preview .preview-excerpt {
    display: none;
}
.other-blogs-home .article-preview .read-more-btn {
    display: none;
}

.latest-blogs .article-preview .preview-link a {

}

@media (max-width: 768px) {
    input[type="email"] {
        font-size: 15px;
    }
    .benefits-list {
        flex-direction: column;
    }
    .sample-content-container {
        justify-content: center;
        text-align: center;
    }
    .sample-text {
        text-align: center;
    }
    h1 {
        font-size: 45px;
    }
    .cta-section h1 {
        font-size: 30px;
    }
    .cta-section h2 {
        font-size: 30px;
    }
    .cta-section h3 {
        font-size: 25px;
    }
    .cta-section h5 {
        font-size: 20px;
    }
    h2 {
        font-size: 25px;
    }
    .sample-text h2 {
        font-size: 25px;
    }
    .sample-text p {
        font-size: 20px;
    }
    .cta-section {
        padding: 40px 20px;
    }
    .newsletter-benefits {
        padding: 40px 20px;
    }
    .sample-content {
      position: relative;
      padding: 0;
      padding-top: 100px;
      color: white;
      overflow: hidden;
      background-color: #a4dbe8;

      /* Create a pseudo-element for the top half background color */
      &::before {
        display: none;
      }

      /* Create a pseudo-element for the background image */
      &::after {
        content: "";
        background-image: none;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: black;
        position: absolute;
        top: -1000px;
        right: -1000px;
        bottom: -1000px;
        left: -1000px;

        /* Apply the rotation transformation to the pseudo-element */
        transform: rotate(-3deg);
      }
    }
    .latest-blogs {
        flex-direction: column;
        padding: 10px 0;
    }
    .latest-blogs .article-preview {
        gap: 0;
    }
    .home-archives-list {
        flex-direction: column;
        padding: 0;
    }
    .home-archives-list .newsletter-preview .container .reportDiv h2 {
        font-size: 18px;
    }
    .home-archives-list .newsletter-preview .container .reportDiv h1 {
        font-size: 20px;
    }
    .home-archives-list .newsletter-preview .container .reportDiv li,
    .home-archives-list .newsletter-preview .container .reportDiv p {
        font-size: 15px;
    }

}

