/* confirmation-page.css */
.confirmation-page {
  min-height: 50vh;
  display: flex;
  align-items: center;
  padding: 30px;
  max-width: 100%;
  justify-content: center;
  margin: 190px 0;
}
.stock-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.stock-inputs input {
    width : 50%;
}
.confirmation-div {
      text-align: center;
      padding: 20px;
      background-color: #f5f5f5;
      border-radius: 10px;
      margin: 20px auto;
      height: auto;
      max-width: 100%;
      min-height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
}
.confirmation-div h1 {
  color: #333;
  font-size: 50px;
  margin-top: 0;
  margin-bottom: 25px;
}

.confirmation-div p {
  color: #555;
  font-size: 18px;
  margin: 10px 0;
}

.confirmation-div a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  margin: 10px 0;
  display: inline-block;
}

/* Add these styles to ConfirmationPage.css */
.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #a4dbe8; /* Button background color */
  color: #007bff; /* Text color */
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  margin: 10px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3; /* Change color on hover */
  color: white;
}
.buttonSubmit {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff; /* Button background color */
    color: #a4dbe8; /* Text color */
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    margin: 10px;
    cursor: pointer;
}
.buttonSubmit:hover {
    background-color: #a4dbe8; /* Button background color */
    color: #007bff; /* Text color */
}
.disabled-button {
  background-color: #ccc; /* Change the background color to a gray shade */
  color: #888; /* Change the text color to a lighter gray */
  cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
}
.disabled-button:hover {
    background-color: #ccc; /* Change the background color to a gray shade */
    color: #888; /* Change the text color to a lighter gray */
    cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
}
.buttonsOnConfirmation {
    display: flex;
    justify-content: space-around;
    gap: 30px;
    margin-top: 30px;
}
.how-did-you-hear-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px 0;
    gap: 15px;
}
.how-did-you-hear-form h5 {
    font-size: 25px;
    margin: 0;
}
.how-did-you-hear-form-div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.howDidYouHearLabel{
    font-size: 25px;
}
.howDidYouHearText{
    font-size: 20px;
    width: 75%;
    padding: 10px;
    text-align: center;
}

@media (max-width: 768px) {
    .buttonsOnConfirmation {
        flex-direction: column;
        margin: 0;
    }
    .confirmation-page {
        margin: 0;
    }
    .confirmation-div h1 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .confirmation-div h2 {
        font-size: 20px;
    }
    .how-did-you-hear-form h5 {
        font-size: 18px;
    }
    .how-did-you-hear-form {
        padding: 10px 0;
    }
}