/* newsletterarchives.css */

.newsletter-archives {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
}

.newsletter-archives h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Style for individual newsletter previews (NewsletterArchivePreview component) */
.newsletter-preview {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}

.newsletter-preview h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.newsletter-preview p {
  font-size: 16px;
  line-height: 1.5;
}

/* Add more styles as needed */

/* Style for the list of archived newsletters */
.archives-list {
  list-style-type: none;
  padding: 0;
}
