.container {
    margin: 20px auto;
    padding: 20px;
    background-color: black;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.transcript {
    background-color: black;
    text-align: left;
}
.transcript h1 {
    font-size: 28px;
    color: #a4dbe8;
    text-align: center;
}
.transcript h2 {
    font-size: 24px;
    color: #a4dbe8;
    margin-top: 20px;
    text-align: left;
}
.transcript ul {
    list-style: disc;
    margin-left: 20px;
    text-align: left;
}
.transcript li {
    font-size: 16px;
    color: white;
    line-height: 1.5;
}
.summary, .transcript {
    background-color: #1f1f1f;
    padding: 20px;
    margin-top: 20px;
}
.transcript p {
    font-size: 16px;
    color: white;
    line-height: 1.5;
}
.stockCharts {
    text-align: center;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
}
.stockChart{
    max-width: 100%;
    height: auto;
}
.stockChartItem {
    display: inline-block;
    margin: 5px;
    text-align: center;
    max-width: 100%
}
.unsub {
    text-align: center;
}
.newsletter-preview-read-more-button{
    margin: 20px;
}
.stockChart-row {
    display: flex;
        flex-direction: column;
        align-items: center;
}
.introDiv {
    border-radius: 20px;
    background-color: #1f1f1f;
    padding: 20px;
}
.introDiv h1 {
    font-size: 30px;
    margin: 0;
    color: #a4dbe8;
}
.introDiv p {
    text-align: center;
    color: white;
}
.reportDivTopStocks {
    display: none;
}
@media (max-width: 768px) {
    .container img {
        width: 50%;
        height: 50%;
    }
    .stockChart{
        height: auto !important;
        width: auto !important;
    }

}
