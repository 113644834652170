/* Unsubscribe.css */

.unsub {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.form1 {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.unsub h1 {
    color: #333;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
    width: 75%;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #444;
}

.form-group input[type="email"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.button:hover {
    background-color: #0056b3;
}

.message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
}

.message.error {
    background-color: #dc3545;
}

.message.success {
    background-color: #28a745;
}
.unsub button {
    padding: 20px;
    margin: 20px;
}

.btns {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .btns {
        flex-direction: column;
    }

}