/* Bonds.css */

.bonds-market {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.bonds-market h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.bonds-market p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

.bonds-market h2 {
  font-size: 20px;
  color: #333;
  margin-top: 20px;
  text-align: left;
  padding: 20px 0;
}

.bonds-market ul {
  list-style: disc;
  margin-left: 20px;
  font-size: 16px;
  color: #666;
}

.bonds-market .example-email {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.bonds-market .example-email h3 {
  font-size: 18px;
  color: #333;
}

.bonds-market .example-email .email-content {
  margin-top: 10px;
}

.bonds-market .example-email .email-content p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

.bonds-market .example-email-picture {
  margin-top: 20px;
  text-align: center;
}

.bonds-market .example-email-picture img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.email-content h4 {
  text-align: center;
}

.email-content ul {
  display: flex;
  flex-direction: column;
}

.bonds-market ul {
  text-align: left;
}
