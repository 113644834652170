/* Styles for the outer container */
.referral-sign-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 140px 20px;
  background-color: #f1f1f1;
}

/* Styles for error messages */
.error {
  color: red;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Styles for the subscriber info container */
.subscriber-info {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styles for the welcome header */
.welcome-header {
  font-size: 60px;
  color: #333;
  margin-bottom: 10px;
}


/* Styles for the signup form info */
.signup-form-info {
  font-size: 16px;
  color: #444;
  margin-bottom: 20px;
}

/* Styles for the welcome section */
.welcome-section {
  text-align: left;
  margin-top: 75px;
  display: flex;
  width: 50%;
  flex-direction: column;
}

/* Styles for the welcome header (first) */
.welcome-header-first {
  font-size: 40px;
  color: #333;
  margin-bottom: 0;
}

/* Styles for the welcome header (main) */
.welcome-header {
  font-size: 28px;
  color: #333;
  margin-bottom: 10px;
}

/* Styles for the welcome text */
.welcome-text {
  font-size: 24px;
  font-weight: 300;
  color: #444;
  margin-bottom: 0;
}
.welcome-text-2 {
  font-size: 24px;
  font-weight: 450;
  color: #444;
  margin-bottom: 20px;
}

/* Styles for the signup form info */
.signup-form-info {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

/* Styles for the unique selling points section */
.unique-selling-points {
  text-align: center;
  margin-bottom: 30px;
}

/* Styles for the USP header */
.usp-header {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Styles for the USP list */
.usp-list {
  font-size: 18px;
  color: #444;
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 20px;
}

/* Styles for the testimonials section */
.testimonials {
  text-align: center;
  margin: 30px 0;
  width: 50%;
  text-align: left;
}

/* Styles for the testimonials header */
.testimonials-header {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Styles for the testimonials list */
.testimonial-list {
  font-size: 18px;
  color: #444;
  list-style-type: square;
  list-style-position: inside;
  margin-left: 20px;
}
.testimonials-text{
    font-style: italic;
    font-size: 18px;
}

.roboTitleSignUp{
    color: blue;
    font-style: italic;
}

@media (max-width: 768px) {
    .welcome-section {
        width: 100%;
        margin-top: 0;
    }
    .referral-sign-up {
        padding: 0;
    }
    .welcome-header-first {
        font-size: 30px;
    }
    .welcome-text {
        font-size: 20px;
    }
    .welcome-text-2 {
        font-size: 20px;
    }
    .testimonials {
        width: 100%;
    }

}

/* Add more styles as needed */
