/* Article.css */
.article {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 20px auto;
  max-width: 80%;
}

.img-responsive {
  max-width: 100%;
  height: 300px;
  border-radius: 10px;
}

.article-title {
  font-size: 35px;
  font-weight: bold;
  margin: 20px 0;
  padding: 20px;
}
.publication-date-on-post{
    text-align: left;
    font-style: italic;
    font-size: 20px;
    padding: 0 20px;
}
.author-on-post{
    text-align: left;
    font-style: italic;
    font-size: 20px;
    padding: 0 20px;
}
.article-content {
  font-size: 20px;
  line-height: 1.5;
  margin-top: 20px;
  text-align: left;
}
/* Article.css */
/* ... (your existing styles) */

.return-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.return-button:hover {
  background-color: #0056b3;
}
.buttonsOnArticle {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

.returnToBlogButton {
    color: white;
    background-color: blue;
    border: none;
    border-radius: 4px;
    padding: 20px 30px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    animation: bounceIn 1s ease-in-out;
}
.returnToBlogButton:hover {
    background-color: #a4dbe8;
}
.article-content p {
    font-size: 25px;
}

/* Style for share links */
.share-links {
    display: flex;
    gap: 10px;
    margin: 20px 0;
    padding: 20px;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
}

.share-link {
  text-decoration: none; /* Remove underlines */
  color: #007BFF; /* Set the text color */
  display: flex; /* Flex layout to align icon and text */
  align-items: center; /* Center vertically */
}

/* Style for the Font Awesome icons */
.share-link .svg-inline--fa {
  margin-right: 5px; /* Add some space between the icon and text */
}

/* Optional: Hover effect */
.share-link:hover {
  color: #a4dbe8; /* Change text color on hover */
}


@media (max-width: 768px) {
    .article-title {
        font-size: 28px;
        padding: 0;
    }
    .article-content {

    }
    .author-on-post{
        padding-top: 50px;
        font-size: 12px;
    }
    .publication-date-on-post{
        font-size: 12px;
    }
}