/* TranscriptExtractor.css */

.containerTX {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.input-group {
  margin: 10px 0;
}

.input-group input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button-group {
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 20px;
}

.button-group button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-group button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.email-preview {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.email-preview h2 {
  font-size: 18px;
  margin-top: 0;
}

.custom-email {
  margin: 20px 0;
}

.custom-email input[type="text"],
.custom-email textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.custom-email button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.custom-email button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
