/* ArticlePreview.css */
.article-preview {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 50px 100px;
  display: flex;
  align-items: center;
  gap: 50px;
}

.preview-link {
  text-decoration: none;
  color: #333;
  display: flex;
  flex-direction: column;
}

.preview-image {
  max-width: 100%;
  height: 300px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.preview-title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 25px;
}

.preview-excerpt {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.read-more-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.publication-date{
    font-size: 19px;
    padding: 10px;
    min-width: 130px;
}

.read-more-btn:hover {
  background-color: #0056b3;
}
@media (max-width: 768px) {
    .article-preview {
        margin: 10px;
        flex-direction: column;
    }
    .preview-link {
        flex-direction: column;
        align-items: center;
    }
    .preview-image {
        height: auto;
        max-width: 200px;
        max-height: 200px;
    }
    .preview-title {
        font-size: 30px;
        padding: 0;
    }
    .preview-excerpt p {
        font-size: 16px;
    }
}