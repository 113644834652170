/* About.css */

.about {
  padding: 300px 40px;
  background-color: #222;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 75px;
}

.about h1 {
  font-size: 75px;
  margin: 0;
  margin-bottom: 30px;
  color: #a4dbe8;
}

.about-content {
  margin: 0 auto;
  padding: 50px 100px;;
}

.about-content p {
  font-size: 25px;
  line-height: 1.6;
  margin-bottom: 20px;
  opacity: 0.85;
  color: white;
  text-align: left;
}

/* Button styles */
.about-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.about-button:hover {
  background-color: #0056b3; /* Darker shade of blue on hover */
}

/* Animations */
.about h1 {
  animation: fadeInUp 1s ease;
}

.about p {
  animation: fadeIn 1s ease;
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.85;
  }
}
@media (max-width: 768px) {
    .about h1 {
        font-size: 50px;
    }
    .about-content {
        padding: 0;
    }
    .about {
        padding-bottom: 0;
    }
}