/* Contact.css */

.contact {
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactFAQandForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.contactImage {
    width: auto;
    min-width: 150px;
    max-width: 12%;
    padding: 30px;
}

.contact h1 {
  font-size: 50px;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 30px;
  padding-bottom: 30px;
}
.contactForm{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px;
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 75%;;
}


label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
}

input[type="textContact"],
input[type="emailContact"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

textarea {
  resize: vertical;
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
.faqDiv {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: left;
    padding: 50px;
}
.faq {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.faq h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.faq-item {
  border: 1px solid #ccc; /* Add a border around each FAQ item */
  padding: 10px; /* Add padding for spacing */
  border-radius: 5px; /* Rounded corners for the border */
}

.faq-item h3 {
  font-size: 18px;
  cursor: pointer; /* Add a pointer cursor to indicate it's clickable */
  user-select: none; /* Prevent text selection when clicking */
}

.faq-item p {
  font-size: 16px;
}

.faq-item h3:hover {
  text-decoration: underline; /* Underline the question on hover */
}

.faq-item h3.active {
  text-decoration: none; /* Remove underline when the answer is open */
}
@media (max-width: 768px) {
    .faqDiv {
        width: 75%;
        overflow: hidden;
        padding: 30px;
    }
    .contact h1 {
        font-size: 30px;
    }
}