.introduction-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 50px;
  background-color: #1f1f1f;
}

.intro-text {
  max-width: 40%; /* Adjust the width as needed */
  padding: 20px;
  text-align: left;
  color: #a4dbe8;
}
.intro-text h1 {
  color: #a4dbe8;
}
.intro-text p {
  color: white;
}
.intro-text a {
    text-decoration: none;
}
.intro-image {
  max-width: 50%; /* Adjust the width as needed */
}

.intro-image img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 10px; /* Rounded corners for the image */
}

.premium-membership-what-you-get-section .newsletter-benefits .benefit-item {
    flex-basis: calc(50% - 20px);
}
.premium-membership-what-you-get-section .newsletter-benefits .benefit-item .benefit-description h3 {
    font-size: 25px;
}
.premium-membership-what-you-get-section .newsletter-benefits .benefit-item .benefit-description p {
    font-size: 20px;
    text-align: left;
    padding: 0 100px;
}
.premium-subscription-main-area {
  padding-bottom: 500px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  padding: 50px 100px;;
}

.subscription-header {
  flex: 1;
  padding: 20px;
  text-align: left;
}

.subscription-content {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #ccc;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
}

.premium-subscription-prelaunch-page h1 {
  /* Your h1 styles here */
  margin: 0;
  color: blue;
}

.premium-subscription-prelaunch-page p {
  /* Your p styles here */
}

.subscription-benefits {
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Adjust as needed */
  padding: 0 20px;
  align-items: center;
}

.subscription-benefits h1 {
  padding-bottom: 20px;
  font-size: 35px;
  color: blue;
}

.subscription-benefits ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  padding: 15px 60px;

}
.premium-pricing {
    background-color: #f1f1f1;
    border-radius: 10px;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.stickered {
  position: relative;
}
.subscription-header h3 {
    color: blue;
}
.sticker {
  position: absolute;
  top: -10px;
  right: -50px;
  background-color: #ff6347; /* Example color */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}
.premium-pricing h1 {
    padding: 0;
    color: black;
}
.premium-pricing p {
    margin: 0;
    font-size: 15px;
    font-style: italic;
    padding-top: 10px;
}
.subscription-benefits ul li {
  margin-bottom: 10px;
  font-size: 18px;
  padding: 10px 0;
}

.subscription-checkboxes {
  margin-top: 20px;
  border-radius: 10px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.subscription-checkboxes label {
  display: block;
  margin-bottom: 0px;
  font-size: 25px;
}
.subscription-checkboxes input[type="checkbox"] {
  transform: scale(1.5);
}
.success-message,
.error-message {
  /* Your success and error message styles */
}
.green-icon {
  color: green;
  width: 30px;
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.intro-text {
    animation: slideFromLeft 1s ease-out forwards;
}

.intro-image {
    animation: slideFromRight 1s ease-out forwards;
}

@media (max-width: 768px) {
    .introduction-section {
        padding: 20px 0;
        flex-direction: column;
    }
    .intro-text {
        max-width: 100%;
    }
    .premium-subscription-prelaunch-page h1 {
        font-size: 30px;
    }
    .intro-image {
        display: none;
    }
    .premium-membership-what-you-get-section .newsletter-benefits .benefit-item .benefit-description h3 {
        font-size: 24px;
        margin-bottom: 0;
    }
    .premium-membership-what-you-get-section .newsletter-benefits .benefit-item .benefit-description p {
        padding: 0 20px;
        text-align: center;
        font-size: 17px;
    }
    .premium-subscription-main-area {
        flex-direction: column;
        padding: 10px;
    }
    .premium-button {
        padding: 20px 10px;
    }
    .premium-membership-what-you-get-section .newsletter-benefits h2 {
        font-size: 30px;
        margin-bottom: 10px;
    }
    .premium-membership-what-you-get-section .newsletter-benefits .benefit-item img {
        max-width: 50px;
    }
    .subscription-benefits ul {
        padding: 15px;
    }

}