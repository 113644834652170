/* Categories.css */

.categories {
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.categories h1 {
    color: #a4dbe8;
}

.categoriesTitle {
    padding-bottom: 40px;
}
.subscription-explanation p {
    font-size: 30px;
}
.subscription-explanation {
    padding: 0px 80px;
    padding-bottom: 50px;
}
.categoryLink{
    text-decoration: none;
}
.category {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
  max-width: 100%;
  background-color: #1f1f1f;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.category-content {
  flex: 1;
  text-align: left;
  padding: 20px;
  min-width: 50%;
}

.category h2 {
  font-size: 35px;
  margin-bottom: 10px;
  color: #a4dbe8;
}

.category p {
  font-size: 25px;
  line-height: 1.5;
}

.category-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-width: 100%;
}
.category-image img{
  max-width: 100%;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .category {
    flex-direction: column;
    text-align: center;
  }

  .category-content {
    padding: 20px;
  }

  .category-image {
    margin-top: 20px;
  }
  .subscription-explanation {
      padding: 0;
      text-align: left;
  }
}

/* Larger checkboxes */
.larger-checkbox {
  width: 30px; /* Adjust the width to make the checkbox larger */
  height: 30px; /* Adjust the height to make the checkbox larger */
  margin-right: 10px; /* Add some spacing to the right of the checkbox */
}

.checkbox-label {
  display: flex;
  align-items: center;
}

.checkbox-text {
  font-size: 30px; /* Adjust the font size of the checkbox label */
  color: #ff9900
}

/* Style for the "Subscribe" button */

/* Style for the "Subscribe" button */
.contact-btn {
  color: white;
  background-color: #ff9900;
  border: none;
  border-radius: 4px;
  padding: 20px 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  animation: bounceIn 1s ease-in-out; /* Bounce animation */
  text-decoration: none;
}

.contact-btn:hover {
  background-color: #FFBF00; /* Change the background color on hover */
}
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the modal is on top of other content */
}

/* Modal */
.modal {
  background-color: #666;
  padding: 20px;
  border-radius: 5px;
}

/* Modal Image (for controlling the image size) */
.modal-image {
}

/* CSS for the market subscriptions */
.market-subscriptions {
  background-color: #1f1f1f;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
}
.market-subscriptions h2{
    color: #a4dbe8;
    font-size: 30px;
}

.market-label {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 22px;
  color: white;
}

.market-checkbox {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.market-labels-div {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .categories h1 {
        text-align: center;
    }
    .subscription-explanation p {
        font-size: 25px;
        text-align: center;
    }
    .subscription-explanation h1 {
        font-size: 35px;
    }
    .category h2 {
        text-align: center;
    }
    .category p {
        text-align: center;
    }
    .checkbox-label {
        justify-content: center;
    }
    .modal-image {
        width: 100%;
        padding: 0;

    }
    .modal {
        padding: 0;
        padding-bottom: 0;
    }
    .market-subscriptions h2{
        font-size: 25px;
    }
}

