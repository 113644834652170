.feedback {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 300px 50px;
    padding-top: 100px;
    padding-bottom: 800px;
}
.feedback-options {
    padding: 20px;
}
.feedback-options label {
    padding: 5px;
    font-size: 25px;
}
.custom-testimonial {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    align-items: center;
}
.custom-testimonial h1, h3 {
    font-style: italic;
}
.custom-testimonial label {
    padding: 5px;
    font-size: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
}
.custom-testimonial input {
    padding: 5px;
}
.custom-testimonial textarea {
    height: 100px;

}
@media (max-width: 768px) {
    .custom-testimonial h1 {
        font-size: 30px;
    }

    .feedback-options label {
        font-size: 20px;
    }

}